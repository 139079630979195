import React from "react";
import tw, { css } from "twin.macro";

import Process from "../../static/images/triangular-process.png";
import DarkContainer from "../components/DarkContainer";
import Hero from "../components/Hero";
import { Layout } from "../components/Layout/Layout";
import Banner from "./index/Banner";
import DoubleGuarantee from "./index/DoubleGuaranteeBlock";
import HowItWorksIndex from "./index/HowItWorksIndex";
import LogosBlock from "./index/LogosBlock";
import PitchSaveYourself from "./index/PitchSaveYourself";
import ProfilesBlock from "./index/ProfilesBlock";
import UnlockTheSecrets from "./index/UnlockTheSecretsBlock";
import VideoBlock from "./index/VideoBlock";
import YouReadAllBlock from "./index/YouReadAllBlock";
import FreeReport from "./opt_in/FreeReport";

const styles = css`
  article {
    ${tw`sm:max-w-3xl mx-1 sm:mx-auto text-center`}
  }
  article#logos {
    ${tw`max-w-4xl`}
  }
  h2 {
    ${tw`leading-none text-3xl sm:text-5xl font-black mb-10`}
  }
  div section {
    ${tw`py-8 sm:(py-12)`}
  }
  div#bookMeeting {
    ${tw`max-w-lg mx-auto`}
  }
`;

const Index: React.FC = () => {
  return (
    <Layout
      css={styles}
      ogTitle={"Triangular-pod - The Process On Demand Platform"}
      author={"Triangular-pod"}
      ogImage={Process}
      ogDescription={
        "We provide access to available production capacities and experts to people that need to use these resources to create new products. We aim for excellent communication with our partners to understand their requirements and find the perfect match between materials, processing systems, and knowledge."
      }
      ogURL={"www.triangular-pod.com/"}
    >
      <Hero tw="pb-6 sm:(pb-8)">
        <Banner />
      </Hero>
      <VideoBlock />
      <hr />
      <DarkContainer>
        <LogosBlock />
      </DarkContainer>
      <HowItWorksIndex />
      <DarkContainer>
        <ProfilesBlock />
      </DarkContainer>
      <FreeReport />
      <DarkContainer>
        {/* <PitchSaveYourself /> */}
        <LogosBlock />
      </DarkContainer>
      {/* <LogosBlock /> */}
      <YouReadAllBlock tw="sm:max-w-3xl mx-1 sm:mx-auto text-center" />
      <hr />
      <DarkContainer>{/* <YouReadAllBlock tw="sm:max-w-3xl mx-1 sm:mx-auto text-center" /> */}</DarkContainer>
    </Layout>
  );
};

export default Index;
